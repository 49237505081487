<template>
  <div class="pin-input">
    <!--
      NOTE:
      we are deliberately not using inputs of type number here as they
      do not support some features like programmatic selection of text
    -->
    <input
      v-for="(digit, index) in digits"
      ref="inputs"
      :key="index"
      :value="digit"
      @focus="selectInput(index)"
      @input="handleInput(index, $event.target.value)"
      @paste="handlePaste"
      @keydown.left.prevent="focusPrevInput(index)"
      @keydown.right.prevent="focusNextInput(index)"
      @keydown.delete.prevent="handleDelete(index)"
      v-bind:class="{
        input: true,
        error: error,
      }"
      inputmode="numeric"
      autocomplete="off"
      :aria-label="`${pinDigitText} ${index}`"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue';

import i18n from '../i18n';

export default {
  props: {
    length: {
      type: Number,
      default: 4,
    },
    error: Boolean,
    initialValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      digits: new Array(this.length).fill(''),
      pinDigitText: i18n.gettext('PIN digit'),
    };
  },
  methods: {
    handleInput(index, value) {
      // use splice, as this.digits[index] = ... is not reactive
      this.digits.splice(
        index,
        1,
        /^\d*$/.test(value) ? value.slice(-1) : this.digits[index]
      );
      if (/^\d*$/.test(value)) {
        this.focusNextInput(index);
      }
    },
    handlePaste(e) {
      e.preventDefault();
      const text = (e.clipboardData || window.clipboardData).getData('text');
      const exp = new RegExp(`^\\d{${this.digits.length},}`);
      if (exp.test(text)) {
        this.digits = text.slice(0, this.digits.length).split('');
        nextTick(() => this.selectInput(this.digits.length - 1));
      }
    },
    handleDelete(index) {
      // use splice, as this.digits[index] = ... is not reactive
      this.digits.splice(index, 1, '');
      this.focusPrevInput(index);
    },
    selectInput(index) {
      this.$refs.inputs[index].select();
    },
    focusPrevInput(index) {
      if (index > 0) {
        this.selectInput(index - 1);
      }
    },
    focusNextInput(index) {
      if (index < this.digits.length - 1) {
        this.selectInput(index + 1);
      }
    },
  },
  watch: {
    digits() {
      const pin = this.digits.map((digit) => digit || ' ').join('');
      this.$emit('change', pin);
    },
  },
  mounted() {
    if (
      this.initialValue &&
      this.initialValue.replace(/\D/g, '').length === this.length
    ) {
      this.digits = this.initialValue.split('');
    }
    this.selectInput(0);
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';
.pin-input {
  display: flex;
  gap: 1rem;

  input,
  input:hover {
    font-size: calc(var(--font-size) * 1.5) !important;
    text-align: center;
    color: var(--theme-accent-color);
    border-color: var(--theme-accent-color);
    caret-color: var(--theme-accent-color) !important;

    &.error {
      color: $danger;
      border-color: $danger;
      caret-color: $danger !important;
    }
  }
}
</style>
