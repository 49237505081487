<template>
  <div
    :class="['checkout-info-header', { 'has-room-choice': txtSelectedArea }]"
  >
    <div class="checkout-info-header-info">
      <span class="checkout-info-header-info-title">{{ txtDate }}</span>
      <div class="checkout-info-header-info-content">
        {{ txtFormattedDate }}
      </div>
    </div>

    <div class="checkout-info-header-info">
      <span class="checkout-info-header-info-title">{{ txtTime }}</span>
      <div class="checkout-info-header-info-content">
        {{ txtFormattedTime }}
      </div>
    </div>

    <div class="checkout-info-header-info">
      <span class="checkout-info-header-info-title">{{ txtGuestNum }}</span>
      <div class="checkout-info-header-info-content">
        {{ state.formValues.num }}
      </div>
    </div>

    <div class="checkout-info-header-info" v-if="txtSelectedArea">
      <span class="checkout-info-header-info-title">{{ txtArea }}</span>
      <div class="checkout-info-header-info-content">{{ txtSelectedArea }}</div>
    </div>
  </div>
</template>

<script>
import i18n from '../../i18n';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

export default {
  props: ['state'],
  data: () => {
    return {
      txtDate: i18n.gettext('Date'),
      txtTime: i18n.gettext('Time'),
      txtGuestNum: i18n.gettext('Guest No.'),
      txtArea: i18n.gettext('Area'),
    };
  },
  computed: {
    txtFormattedDate: function () {
      return moment(this.state.formValues.date).format('DD.MM.YYYY');
    },
    txtFormattedTime: function () {
      if (this.state.isDepositOnly) {
        return moment(this.state.formValues.date).format('HH:mm');
      }
      return moment(this.state.selectedAvailability.date).format('HH:mm');
    },
    txtSelectedArea: function () {
      const { resourceGroupName, formValues, facilityResourceGroups } =
        this.state;

      if (resourceGroupName) {
        return resourceGroupName;
      }
      if (formValues.resourceGroup) {
        return facilityResourceGroups.find(
          (option) => option.id === formValues.resourceGroup
        )?.name;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

.checkout-info-header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  border: 1px solid var(--theme-font-color-opacity-5);
  color: var(--theme-accent-color);
  border-radius: 10px;
  padding: 1rem;

  &:hover {
    border-color: var(--theme-accent-color);
  }

  @at-root #{$glass} & {
    background: var(--theme-background-color-opacity-7);
    border: none;
  }
  @at-root #{$broadway} & {
    border: none;
    box-shadow: 0px 0px 10px 0px var(--theme-accent-color);
    border-radius: 20px;
  }
}

.checkout-info-header.has-room-choice {
  grid-template-columns: repeat(2, 1fr);
}

.checkout-info-header-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}

.checkout-info-header-info-title {
  font-size: calc(var(--font-size) * 0.75);
  text-overflow: ellipsis;
  overflow: hidden;
}

.checkout-info-header-info-content {
  font-size: var(--font-size);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
