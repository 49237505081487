<template>
  <div>
    <div class="field paypal-field">
      <b-radio
        v-on:input="onPayPalInput"
        native-value="PAYPAL"
        v-model="state.formValues.bookingDeposit.payment_type"
        v-bind:disabled="state.isSavingPayment || state.isSavingBooking"
      >
        {{ txtPayPal }}
      </b-radio>
    </div>
    <div v-show="showPayPal" class="widget-paypal">
      <div v-if="error" class="widget-paypal-error">{{ error }}</div>
      <div v-else-if="isLoading" class="widget-spinner"></div>
      <div class="widget-paypal-element">
        <div ref="paypal" id="paypal-element"><!-- paypal --></div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadPayPalSDK } from '../../common/utils';
import i18n from '../i18n';

export default {
  props: ['state'],
  data: () => {
    return {
      error: null,
      isLoading: !window.paypal,
      txtPayPal: i18n.gettext('Paypal'),
    };
  },
  mounted: function () {
    if (!window.paypal) {
      loadPayPalSDK({
        clientId: this.state.paypalClientId,
        currency: this.state.facilityCurrency,
        intent: this.state.statusCode ? 'capture' : 'authorize',
      })
        .then(() => {
          clearTimeout(this.paypalSDKTimeout);
          this.state.initPayPal(window.paypal);
          this.error = null;
          this.isLoading = false;
          if (this.showPayPal) {
            this.onPayPalInput();
          }
        })
        .catch((e) => {
          this.error = e;
          this.isLoading = false;
        });

      this.paypalSDKTimeout = setTimeout(() => {
        this.error = i18n.gettext(
          'Error loading PayPal - please choose another payment method.'
        );
        this.isLoading = false;
      }, 5000);

      return;
    }

    this.$nextTick(function () {
      if (this.showPayPal) {
        this.onPayPalInput();
      }
    });
  },
  computed: {
    showPayPal: function () {
      return this.state.formValues.bookingDeposit.payment_type === 'PAYPAL';
    },
  },
  methods: {
    onPayPalInput: function () {
      this.state.paymentError = false;
      this.$refs.paypal.innerHTML = '';
      this.state.paypal
        .Buttons({
          createOrder: this.state.createPayPalOrder,
          onApprove: this.state.processPayPal,
        })
        .render('#paypal-element');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.paypal-field,
.c-card-field {
  color: var(--theme-font-color);
}

.widget-paypal .widget-spinner {
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;

  &:after {
    position: static;
  }
}

.widget-paypal-error {
  color: $danger;
  text-align: center;
}
</style>
