import * as Sentry from '@sentry/browser';
import axios from 'axios';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import { localeFormattedPrice } from '../common/utils';
import { BamboraCheckout } from '../common';
import i18n from './i18n';

class AppState {
  get termsAndConditionsUrl() {
    return this.isOrderbirdFacility
      ? i18n.gettext(
          'https://support.orderbird.com/de_DE/gaestemanagement-von-orderbird-starten/gaestemanagement-nutzungsbedingungen-preisliste'
        )
      : this.facilityHidePublicBranding && this.facilityTermsConditions
        ? this.facilityTermsConditions
        : i18n.gettext('https://www.resmio.com/en/terms-conditions-b2c/');
  }

  get privacyPolicyUrl() {
    return this.isOrderbirdFacility
      ? i18n.gettext('https://www.orderbird.com/en/privacy')
      : this.facilityHidePublicBranding && this.facilityPrivacyPolicy
        ? this.facilityPrivacyPolicy
        : i18n.gettext('https://www.resmio.com/en/privacy-policy/');
  }

  get legalNoticeUrl() {
    return this.isOrderbirdFacility
      ? i18n.gettext('https://www.orderbird.com/en/legal-notice')
      : this.facilityHidePublicBranding && this.facilityLegalNotice
        ? this.facilityLegalNotice
        : i18n.gettext('https://www.resmio.com/en/imprint/');
  }

  get cancellationPolicyUrl() {
    return this.facilityCancellationPolicy;
  }

  init = (config) => {
    let finalStep = 'BOOKING';

    // This is needed to handle already cancelled/confirmed
    // bookings for the external view
    if (config.external) {
      if (config.owner) {
        if (config.booking.status === 'cancelled') {
          finalStep = 'CANCELLED_OWNER';
        } else if (config.booking.status === 'confirmed') {
          finalStep = 'CONFIRMED_OWNER';
        } else {
          finalStep = 'EXTERNAL_OWNER';
        }
      } else {
        if (config.booking.status === 'cancelled') {
          finalStep = 'CANCEL_WAITLIST';
        } else {
          finalStep = 'EXTERNAL';
        }
      }
    }
    this.step = finalStep;
    this.stepForward = true;

    this.facilityResourceUri = config.facilityResourceUri;
    this.language = config.language;
    this.staticUrl = config.staticUrl;
    this.apiBaseUrl = config.apiBaseUrl;
    this.facilityId = config.facilityId;
    this.facilityName = config.facilityName;
    this.facilityStreet = config.facilityStreet;
    this.facilityCity = config.facilityCity;
    this.facilityZip = config.facilityZip;
    this.facilityCountry = config.facilityCountry;
    this.facilityPhone = config.facilityPhone;
    this.facilityEmail = config.facilityEmail;
    this.preferredContactMethod = config.preferredContactMethod;
    this.isPhonePreferredAndAvailable =
      config.preferredContactMethod === 'phone' && config.facilityPhone
        ? true
        : false;
    this.isEmailPreferredAndAvailable =
      config.preferredContactMethod === 'email' && config.facilityEmail
        ? true
        : false;
    this.facilityTimeZone = config.facilityTimeZone;
    this.facilityWebsite = config.facilityWebsite;
    this.facilityHours =
      config.facilityOpeningHours && config.facilityOpeningHours.length > 0
        ? config.facilityVisualOpeningHours &&
          config.facilityVisualOpeningHours.length > 0
          ? config.facilityVisualOpeningHours
          : config.facilityOpeningHours
        : null;
    this.facilityClosedText = config.facilityClosedText;
    this.facilityCustomText = config.facilityCustomText;
    this.facilityNewsletterSignupText = config.facilityNewsletterSignupText;
    this.facilityCustomFields = config.facilityCustomFields;
    this.facilityNotAvailableText = config.facilityNotAvailableText;
    this.facilityNotAvailableLink = config.facilityNotAvailableLink;
    this.facilityBookingRequestText = config.facilityBookingRequestText;
    this.facilityAutomaticallyConfirmBookings =
      config.facilityAutomaticallyConfirmBookings;
    this.facilityAutomaticallyConfirmBookingsUntilGroupSize =
      config.facilityAutomaticallyConfirmBookingsUntilGroupSize;
    this.facilityBookingAutoConfirmationMaxGroupSize =
      config.facilityBookingAutoConfirmationMaxGroupSize;
    this.facilityReachedBookingLimit = config.facilityReachedBookingLimit;
    this.facilityCurrency = config.facilityCurrency;
    this.facilityBookingDepositEnabled =
      config.bookingDepositEnabledByAddon &&
      config.facilityBookingDepositEnabled;
    this.facilityBookingDepositStripeEnabled = config.hasStripe;
    this.facilityBookingDepositSepaEnabled =
      config.facilityBookingDepositSepaEnabled &&
      this.facilityCurrency === 'EUR';
    this.facilityBookingDepositPayPalEnabled =
      config.hasPayPal && !config.stripeDelayedCharge;
    this.facilityBookingDepositBamboraEnabled =
      !config.hasStripe && config.hasBambora;
    this.paypalClientId = config.paypalClientId;
    this.stripePublishKey = config.stripePublishKey;
    this.facilityBookingDepositAmount = config.facilityBookingDepositAmount;
    this.facilityBookingDepositPerGuest = config.facilityBookingDepositPerGuest;
    this.facilityBookingDepositDescription =
      config.facilityBookingDepositDescription;
    this.facilityBookingDepositReference =
      config.facilityBookingDepositReference;
    this.facilityBookingDepositGroupSize =
      config.facilityBookingDepositGroupSize;
    this.facilityBookingDepositAllowedHours =
      config.facilityBookingDepositAllowedHours;
    this.facilityBookingDepositDateRanges =
      config.facilityBookingDepositDateRanges;
    this.facilityBookingInterval = config.facilityBookingInterval;
    this.facilityReservationEndTimeEnabled =
      config.facilityReservationEndTimeEnabled;
    this.cancelWithoutFeesDays = config.cancelWithoutFeesDays;
    this.facilityPromoTimes = config.facilityPromoTimes || [];
    this.promoTimesById = this.facilityPromoTimes.reduce((map, pt) => {
      map[pt.id] = pt;
      return map;
    }, {});
    this.facilityMaxNum = config.facilityMaxNum;
    this.facilityMinNum = config.facilityMinNum;
    this.facilityResourceGroups = [...config.facilityResourceGroups].sort(
      (r1, r2) =>
        r1.priority > r2.priority ? -1 : r1.priority < r2.priority ? 1 : 0
    );
    this.facilityEnableOnlineWaitlist = config.facilityEnableOnlineWaitlist;
    this.facilityMinBookInAdvanceHours = config.facilityMinBookInAdvanceHours;
    this.facilityMaxBookInAdvanceDays = config.facilityMaxBookInAdvanceDays;
    this.facilityHidePublicBranding = config.facilityHidePublicBranding;
    this.facilityTermsConditions = config.facilityTermsConditions;
    this.facilityPrivacyPolicy = config.facilityPrivacyPolicy;
    this.facilityLegalNotice = config.facilityLegalNotice;
    this.facilityCancellationPolicy = config.facilityCancellationPolicy;
    this.facilityCancelWithoutFees = config.facilityCancelWithoutFees;
    this.facilityCancelWithoutFeesDays = config.facilityCancelWithoutFeesDays;
    this.isOrderbirdFacility = config.isOrderbirdFacility;

    this.fullscreen = config.fullscreen;
    this.textColor = config.textColor;
    this.fontSize = config.fontSize;
    this.resourceGroup = config.resourceGroup;
    this.resourceGroupName = config.resourceGroupName;
    this.commentsDisabled = config.commentsDisabled;

    this.disableFullscreenSidebar = !this.facilityStreet && !this.facilityPhone;

    this.availabilities = [];
    this.booking = config.booking;
    this.isDepositOnly = !!this.booking.booking_deposit_id;
    this.isDepositCharged = !!this.booking.is_deposit_charged;
    this.depositStatus = this.booking.deposit_status;
    this.depositAmount = this.booking.deposit_amount;
    this.depositCurrency = this.booking.deposit_currency;
    this.depositConfirmUrl = this.booking.deposit_confirm_url;
    this.selectedAvailability = {};
    this.anyRoomChoiceDisabled = config.anyRoomChoiceDisabled;
    this.roomChoiceEnabled = config.roomChoiceEnabled;
    this.formValues = {
      date: config.nextAvailability
        ? this.getJavascriptDate(moment(config.nextAvailability, 'YYYY-MM-DD'))
        : this.getJavascriptDate(moment()),
      num:
        this.facilityMinNum === 1 && this.facilityMaxNum === 1
          ? 1
          : this.facilityMinNum > 2
            ? this.facilityMinNum
            : 2,
      firstName: config.defaultName,
      lastName: '',
      email: config.defaultEmail,
      phone: config.defaultPhone,
      comment: config.defaultComment,
      subscribe: !!config.defaultSubscribe,
      source: config.source,
      bookingDeposit: null,
      bankName: '',
      customFields: this.facilityCustomFields.map((field) =>
        field.is_checkbox ? false : ''
      ),
      notes: '',
      bookingResponse: config.bookingOwnerStatus,
      resourceGroup: this.resourceGroup
        ? Number(this.resourceGroup)
        : this.roomChoiceEnabled &&
            this.anyRoomChoiceDisabled &&
            this.facilityResourceGroups.length > 1
          ? this.facilityResourceGroups[0].id
          : '',
      phonenumber2: '',
      _render_time: undefined,
      security_code: undefined,
    };
    this.phoneIsValid = false;

    this.isGettingAvailabilities = false;
    this.availabilityError = false;
    this.isSavingBooking = false;
    this.bookingError = false;
    this.stripeCard = null;
    this.isSavingPayment = false;
    this.isSavingBookingDeposit = false;
    this.paymentError = false;

    this.facebookAppId = config.fbAppId;
    this.facebookChannelUrl = config.fbChannelUrl;
    this.facebookInitialized = false;
    this.facebookAccessToken = '';
    this.facebookError = false;
    this.statusCode = config.statusCode;

    this.isAddingWaitlist = false;
    this.googleMapsApiUrl = config.googleMapsApiUrl;

    this.facebookLogin = config.facebookLogin;
    this.newsletterSignup = config.newsletterSignup;
    this.widgetStyle = config.widgetStyle;
    this.borderRadius = config.borderRadius;
    this.facilityLogo = config.facilityLogo;
    this.disableBranding = config.disableBranding;
    this.imageResizeService = config.imageResizeService;

    this.stripeDelayedCharge = config.stripeDelayedCharge;
    this.stripeSetupPaymentIntentId = config.stripeSetupPaymentIntentId;
    this.isChargingStripeSetupPaymentIntent = false;

    // Picker States
    this.showDatepicker = false;
    this.showMonthpicker = false;
    this.showGuestSelect = false;
    this.showRoomSelect = false;

    // Honeypot field required state
    this.isHoneypotFieldRequired = true;

    // security code
    this.SECURITY_CODE_LENGTH = 4;
    this.SECURITY_CODE_TIMEOUT = 300; // security code times out after 300 seconds
    this.ONE_TIME_FACILITY_TOKEN_TIMEOUT = 3600; // one time facility token times out after 3600 seconds
    this.bookingSecurityCodeEnabled = config.bookingSecurityCodeEnabled;
    this.securityCodeError = null;
    this.securityCodeExpiryDateByEmail = new Map();
    this.oneTimeFacilityToken = config.oneTimeFacilityToken;
    this.oneTimeFacilityTokenExpiryDate = moment().add(
      this.ONE_TIME_FACILITY_TOKEN_TIMEOUT,
      'seconds'
    );

    this.displayRoomPicker =
      !this.resourceGroup &&
      this.roomChoiceEnabled &&
      this.facilityResourceGroups.length > 1;

    this.numOptions = new Array(this.facilityMaxNum - this.facilityMinNum + 1)
      .fill()
      .map((_, i) => this.facilityMinNum + i);

    if (this.isDepositOnly) {
      if (
        (this.depositStatus === 'setup_waiting' && this.depositConfirmUrl) ||
        this.depositStatus === 'setup_success'
      ) {
        if (this.stripeSetupPaymentIntentId) {
          this.chargeStripeSetupPaymentIntent();
        }
        this.step = 'DEPOSIT_CONFIRM';
      } else {
        this.updateFormValuesFromPreviousBooking();
        this.startDeposit();
      }
    }

    this.getAvailabilities();

    // lazy init facebook
    setTimeout(() => this.initFacebook(), 2000);
  };

  initStripe = (stripe) => {
    this.stripe = stripe;
    this.stripeCard = {};
  };

  initPayPal = (paypal) => {
    this.paypal = paypal;
  };

  initFacebook = () => {
    if (window.FB && window.FB.Event && window.FB.init) {
      this.facebookCallback();
    } else {
      window.fbAsyncInit = this.facebookCallback;
    }
  };

  facebookCallback = () => {
    this.facebookInitialized = true;
    const options = {
      xfbml: true,
      cookie: true,
      version: 'v4.0',
      appId: this.facebookAppId,
    };
    window.FB.init(options);
  };

  //FORMATTER
  formatPrice = (amount) => {
    return localeFormattedPrice(amount, {
      languageCode: this.language,
      currencyCode: this.facilityCurrency,
    });
  };

  updateFormValuesFromPreviousBooking = () => {
    this.formValues.date = this.getJavascriptDate(this.booking.date);
    this.formValues.num = this.booking.num;
  };

  onFacebookLogin = () => {
    if (this.facebookInitialized) {
      this.facebookError = false;
      window.FB.login(this.onFacebookLoginResponse, { scope: 'email' });
    }
  };

  onFacebookLoginResponse = (response) => {
    if (response.status === 'connected') {
      if (response.authResponse) {
        this.facebookError = false;
        this.facebookAccessToken = response.authResponse.accessToken;
        this.onFacebookAuthSuccess();
      } else if (response.status === 'not_authorized') {
        this.facebookError = true;
      }
    } else {
      this.facebookError = true;
    }
  };

  onFacebookAuthSuccess = () => {
    window.FB.api(
      '/me?fields=first_name,last_name,email&access_token=' +
        this.facebookAccessToken,
      (user) => {
        this.formValues.firstName = user.first_name;
        this.formValues.lastName = user.last_name;
        this.formValues.email = user.email;
      }
    );
  };

  startDeposit = () => {
    const defaultAmount = this.facilityBookingDepositPerGuest
      ? Math.round(
          this.facilityBookingDepositAmount * this.formValues.num * 100
        ) / 100
      : this.facilityBookingDepositAmount;
    this.formValues.bookingDeposit = {
      id: this.booking.booking_deposit_id,
      payment_type: this.facilityBookingDepositStripeEnabled
        ? 'CCARD'
        : this.facilityBookingDepositBamboraEnabled
          ? 'BAMBORA'
          : this.facilityBookingDepositPayPalEnabled
            ? 'PAYPAL'
            : 'OUTSTANDING',
      currency: this.facilityCurrency,
      facility: this.facilityResourceUri,
      amount: this.isDepositOnly ? this.booking.deposit_amount : defaultAmount,
      reference:
        this.facilityBookingDepositReference ||
        i18n.sprintf(
          i18n.gettext('Booking for %s on %s'),
          this.facilityName,
          moment(this.getYMDDate(this.formValues.date), 'YYYY-MM-DD').format(
            'DD.MM.YY'
          )
        ),
      //customer_email: !this.isDepositOnly ? this.formValues.email : '',
      customer_email: this.formValues.email,
      capture_manually: !this.isDepositOnly,
    };

    if (
      !this.isDepositOnly &&
      this.isBookingDepositDisabled(this.selectedAvailability.date)
    ) {
      this.saveBooking();
    } else {
      this.stepForward = true;
      this.step =
        this.isDepositCharged || this.depositStatus === 'setup_success'
          ? 'CONFIRMATION'
          : 'DEPOSIT';
    }
  };

  getJavascriptDate = (d) => {
    const date = moment(d);
    return new Date(
      date.year(),
      date.month(),
      date.date(),
      date.hours(),
      date.minutes(),
      date.seconds(),
      date.milliseconds()
    );
  };

  getYMDDate = (d) => {
    const month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();

    return `${year}-${month}-${day}`;
  };

  getResizedImageUrl = (imageUrl, { width, height } = {}) => {
    if (!this.imageResizeService) {
      return imageUrl;
    }
    // !! url encoding image parameter might break resize service - don't do it !!
    return `https://${this.imageResizeService}/?image=${imageUrl}&width=${
      width || ''
    }&height=${height || ''}`;
  };

  // CHECKS
  displayCustomFieldsStep = () => {
    return (
      this.facilityCustomText !== '' ||
      ((this.facilityCustomFields.length > 0 || !this.commentsDisabled) &&
        !this.isAddingWaitlist)
    );
  };

  isSelectedAvailability = (availability) => {
    return (
      availability.resource_uri &&
      availability.resource_uri === this.selectedAvailability.resource_uri
    );
  };

  isBookingInsideDepositAllowedHours = (date) => {
    if (
      !(
        this.facilityBookingDepositAllowedHours ||
        this.facilityBookingDepositDateRanges
      )
    ) {
      return true;
    }
    const momentDate = moment(date);
    if (this.facilityBookingDepositAllowedHours) {
      const weekday =
        momentDate.isoWeekday() === 0 ? 6 : momentDate.isoWeekday() - 1;
      const hours = this.facilityBookingDepositAllowedHours[weekday] || [];
      const momentTime = momentDate.format('HH:mm:ss');
      for (const hourRange of hours) {
        if (momentTime <= hourRange.ends && momentTime >= hourRange.begins) {
          return true;
        }
      }
    }
    if (this.facilityBookingDepositDateRanges) {
      for (const dateRange of this.facilityBookingDepositDateRanges) {
        if (
          moment(dateRange.start_date)
            .startOf('day')
            .isSameOrBefore(momentDate) &&
          moment(dateRange.end_date).endOf('day').isSameOrAfter(momentDate) &&
          momentDate.isBetween(
            moment(`${momentDate.format('YYYY-MM-DD')} ${dateRange.begins}`),
            moment(`${momentDate.format('YYYY-MM-DD')} ${dateRange.ends}`),
            undefined,
            '[]'
          )
        ) {
          return true;
        }
      }
    }

    return false;
  };

  isGroupSizeTooSmallForDeposit = () => {
    return this.formValues.num < this.facilityBookingDepositGroupSize;
  };

  isBookingDepositDisabled = (date) => {
    return (
      !this.facilityBookingDepositEnabled ||
      this.isGroupSizeTooSmallForDeposit() ||
      !this.isBookingInsideDepositAllowedHours(date)
    );
  };

  isBookingDepositChargable = () => {
    return (
      this.facilityBookingDepositEnabled &&
      this.isBookingInsideDepositAllowedHours(this.selectedAvailability.date) &&
      !this.isGroupSizeTooSmallForDeposit() &&
      !this.isAddingWaitlist
    );
  };

  // AVAILABILITY ACTIONS
  getAvailabilities = (params) => {
    this.isGettingAvailabilities = true;
    this.availabilityError = false;
    this.selectedAvailability = {};
    return axios
      .get(`${this.apiBaseUrl}/availability`, {
        params: {
          num: this.formValues.num,
          date__gte: this.getYMDDate(this.formValues.date),
          resource_group: this.formValues.resourceGroup,
          end_of_acceptance_enabled: true,
          ...params,
        },
      })
      .then(this.getAvailabilitiesSuccess)
      .catch(this.getAvailabilitiesError);
  };

  getAvailabilitiesSuccess = (res) => {
    this.isGettingAvailabilities = false;
    this.availabilities = res.data.objects;
  };

  getAvailabilitiesError = (err) => {
    this.isGettingAvailabilities = false;
    this.availabilityError = err;
  };

  selectAvailability = (availability) => {
    this.selectedAvailability = availability;
  };

  setAddingWaitlist = (value) => {
    this.isAddingWaitlist = value;
  };

  // BOOKING ACTIONS
  getBookingRequestParams = () => {
    if (parent !== window) {
      //  Remove the '?' at the start of the string
      let referrerObj = {};
      const referrerParameterString = document.referrer.split(/\?(.*)/)[1];
      if (referrerParameterString) {
        referrerParameterString.split('&').map((param) => {
          if (param) {
            let key = param.split('=')[0];
            let value = param.split('=')[1];
            if (['gclid', 'utm'].includes(key)) {
              referrerObj[key] = value;
            }
          }
        });
      }

      return referrerObj;
    } else {
      return null;
    }
  };

  saveBooking = () => {
    this.bookingError = false;
    this.isSavingBooking = true;

    let comment = '';
    this.facilityCustomFields.forEach((field, i) => {
      if (!this.formValues.customFields[i]) return;
      comment += `${field.name}: ${
        field.is_checkbox
          ? i18n.gettext('Yes')
          : this.formValues.customFields[i]
      }\n`;
    });
    if (this.formValues.comment) {
      comment += comment
        ? `${i18n.gettext('Comment')}: ${this.formValues.comment}`
        : this.formValues.comment;
    }

    let data = {
      comment,
      date: this.selectedAvailability.date,
      email: this.formValues.email,
      facility_resources: [],
      fb_access_token: this.facebookAccessToken,
      name: `${this.formValues.firstName} ${this.formValues.lastName}`,
      newsletter_subscribe: this.newsletterSignup
        ? this.formValues.subscribe
        : false,
      num: this.formValues.num,
      phone: this.formValues.phone,
      promo: this.selectedAvailability.promo?.id,
      resmio_newsletter_subscribe: false,
      resource_group: this.formValues.resourceGroup,
      source: this.formValues.source,
      booking_deposit_id: this.formValues.bookingDepositId,
      booking_request_parameters: this.getBookingRequestParams(),
      // honeypot fields
      phonenumber2: this.formValues.honeypotField,
      _render_time: this.formValues.honeypotRenderTime,
      security_code: this.formValues.security_code,
    };
    if (!this.facilityBookingDepositEnabled) {
      // remove deposit key
      data.booking_deposit_id = null;
    }

    axios
      .post(`${this.apiBaseUrl}/bookings`, data)
      .then(this.saveBookingSuccess)
      .catch(this.saveBookingError);
  };

  saveWaitlist = () => {
    this.bookingError = false;
    this.isSavingBooking = true;
    let data = {
      date: this.selectedAvailability.date,
      name: `${this.formValues.firstName} ${this.formValues.lastName}`,
      num: this.formValues.num,
      email: this.formValues.email,
      phone: this.formValues.phone,
      is_online: true,
      room: this.formValues.resourceGroup || undefined,
    };

    axios
      .post(`${this.apiBaseUrl}/waitlist`, data)
      .then(this.saveBookingSuccess)
      .catch(this.saveBookingError);
  };

  handleOwnerBookingAction = () => {
    axios
      .patch(`/v1/booking_status_update/${this.booking.authentication_code}`, {
        status: this.formValues.bookingResponse,
        message: this.formValues.notes,
      })
      .then((response) => {
        if (this.formValues.bookingResponse === 'confirmed') {
          this.step = 'CONFIRMED_OWNER';
        } else {
          this.step = 'CANCELLED_OWNER';
        }
      })
      .catch(this.saveBookingError);
  };

  removeWaitlistOrBooking = (id, message) => {
    this.bookingError = false;
    this.isSavingBooking = true;
    if (this.booking.ref_num !== null) {
      let data = {
        facility_name: this.facilityName,
        authentication_code: id,
        message,
      };
      axios
        .patch(`/v1/booking_cancel/${id}`, data)
        .then((response) => {
          this.step = 'CANCEL_WAITLIST';
        })
        .catch(this.saveBookingError);
    } else {
      axios
        .delete(`/v1/waitlist_cancel/${id}`)
        .then((response) => {
          this.step = 'CANCEL_WAITLIST';
        })
        .catch(this.saveBookingError);
    }
  };

  saveBookingSuccess = (res) => {
    this.isSavingBooking = false;
    this.booking = { ...res.data, booking_deposit_id: null };
    this.stepForward = true;
    this.step = 'CONFIRMATION';
  };

  saveBookingError = (err) => {
    const errorOrErrorText = this.parseError(err);
    this.bookingError =
      errorOrErrorText instanceof Error
        ? i18n.gettext(
            'There was a problem saving the booking. Please try again.'
          )
        : errorOrErrorText;
    this.isSavingBooking = false;
  };

  saveBookingDeposit = () => {
    this.isSavingBookingDeposit = true;
    const method = this.isDepositOnly ? axios.patch : axios.post;
    const endpoint = this.isDepositOnly
      ? `${this.apiBaseUrl}/booking_deposit/${this.statusCode}/confirm_deposit`
      : `${this.apiBaseUrl}/booking_deposit`;
    method(endpoint, { ...this.formValues.bookingDeposit })
      .then(this.saveBookingDepositSuccess)
      .catch(this.saveBookingDepositError);
  };

  parseError = (err, defaultMessage = null) => {
    let errOrMessage;
    const errCode =
      err.response && err.response.data && err.response.data.error;
    switch (errCode) {
      case 'CUSTOMER_BLOCKED_ERROR':
        errOrMessage = i18n.gettext(
          'Sorry, we do not allow online bookings at the moment.'
        );
        break;
      default:
        errOrMessage = defaultMessage || err;
    }
    return errOrMessage;
  };

  // BOOKING / WAITLIST CANCELLATION

  cancellationReason = '';

  cancelWaitlistOrBooking = () => {
    this.removeWaitlistOrBooking(
      this.booking.authentication_code,
      this.cancellationReason
    );
  };

  cancelNotWaitlist = () => {
    this.step = 'CANCEL_NO_WAITLIST';
  };

  saveBookingDepositError = (err) => {
    this.paymentError = this.parseError(err);
    this.isSavingBookingDeposit = false;
    this.isSavingPayment = false;
  };

  saveBookingDepositSuccess = ({ data }) => {
    this.isSavingBookingDeposit = false;
    if (data && data.requires_action) {
      if (data.payment_intent_client_secret) {
        this.formValues.bookingDepositId = data.id;
        this.confirmPaymentIntent(data.payment_intent_client_secret);
      } else if (data.setup_intent_client_secret) {
        this.formValues.bookingDepositId = data.booking_deposit_id;
        this.handleStripeSetupIntentNextAction(data.setup_intent_client_secret);
      } else {
        throw new Error('not implemented'); // should never happen
      }
    } else {
      this.isSavingPayment = false;
      if (this.isDepositOnly) {
        this.booking = { ...this.booking, status: 'confirmed' };
        this.stepForward = true;
        this.step = 'CONFIRMATION';
      } else {
        this.formValues.bookingDepositId = data.id;
        this.saveBooking();
      }
    }
  };

  confirmPaymentIntent = (intentSecret) => {
    this.isSavingPayment = true;
    this.stripePaymentIntent = true;
    this.stripe
      .confirmCardPayment(intentSecret)
      .then(this.processStripeIntentSuccess)
      .catch(this.processStripeIntentFailure);
  };

  processStripeIntentSuccess = (result) => {
    this.isSavingPayment = false;
    this.stripePaymentIntent = false;
    if (result.error) {
      this.processStripeIntentFailure(result.error.message);
    } else {
      this.formValues.bookingDeposit.payment_intent_id =
        result.paymentIntent.id;
      this.saveBookingDeposit();
    }
  };

  processStripeIntentFailure = (err) => {
    this.paymentError = err;
    this.stripePaymentIntent = false;
  };

  handleStripeSetupIntentNextAction = async (intentSecret) => {
    this.isSavingPayment = true;
    try {
      const result = await this.stripe.handleNextAction({
        clientSecret: intentSecret,
      });
      if (result.error) {
        throw result.error;
      }
      await axios.post(
        `${this.apiBaseUrl}/booking_deposit/${this.formValues.bookingDepositId}/confirm_setup`
      );
      if (this.isDepositOnly) {
        this.depositStatus = 'setup_success';
        this.stepForward = true;
        this.step = 'CONFIRMATION';
      } else {
        this.saveBooking();
      }
    } catch (error) {
      this.paymentError = error.message;
      Sentry.captureException(error, {
        extra: {
          booking_deposit_id: this.formValues.bookingDepositId,
        },
      });
    } finally {
      this.isSavingPayment = false;
    }
  };

  chargeStripeSetupPaymentIntent = async () => {
    this.isChargingStripeSetupPaymentIntent = true;
    try {
      const { data } = await axios.post(
        `${this.apiBaseUrl}/booking_deposit/${this.statusCode}/charge`,
        { payment_intent: this.stripeSetupPaymentIntentId }
      );
      const { error, requires_action, redirect_url } = data;
      if (error) {
        throw new Error(error);
      }
      if (requires_action) {
        this.depositConfirmUrl = redirect_url;
      } else {
        this.depositStatus = 'setup_success';
      }
    } catch (error) {
      this.paymentError = error.message;
      Sentry.captureException(error, {
        extra: {
          payment_intent_id: this.stripeSetupPaymentIntentId,
        },
      });
    } finally {
      this.isChargingStripeSetupPaymentIntent = false;
    }
  };

  // DEPOSIT ACTIONS
  processPayment = () => {
    this.isSavingPayment = true;
    this.paymentError = false;
    switch (this.formValues.bookingDeposit.payment_type) {
      case 'CCARD':
        this.processCard();
        break;
      case 'SEPA-DD':
        this.processIban();
        break;
      case 'BAMBORA':
        this.handleBamboraCheckout();
        break;
      default:
        break;
    }
  };

  processCard = () => {
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.stripeCard,
        billing_details: { name: this.formValues.bookingDeposit.owner_name },
      })
      .then(this.processStripePaymentSuccess)
      .catch(this.processPaymentFailure);
  };

  processIban = () => {
    this.stripe
      .createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: this.stripeCard,
        billing_details: {
          name: this.formValues.bookingDeposit.owner_name,
          email: this.formValues.bookingDeposit.owner_email,
        },
      })
      .then(this.processStripePaymentSuccess)
      .catch(this.processPaymentFailure);
  };

  createPayPalOrder = (data, actions) => {
    return actions.order.create({
      intent: this.isDepositOnly ? 'CAPTURE' : 'AUTHORIZE',
      purchase_units: [
        {
          amount: {
            value: this.formValues.bookingDeposit.amount,
            currency_code: this.facilityCurrency,
          },
          description: this.formValues.bookingDeposit.reference,
        },
      ],
    });
  };

  processPayPal = (data) => {
    if (data.orderID) {
      this.processPayPalPaymentSuccess(data.orderID);
    } else {
      this.processPaymentFailure();
    }
  };

  processPayPalPaymentSuccess = (orderID) => {
    this.paymentError = false;
    this.formValues.bookingDeposit.token = orderID;
    this.saveBookingDeposit();
  };

  processStripePaymentSuccess = (result) => {
    if (result.error) {
      this.isSavingPayment = false;
      this.paymentError = result.error.message;
    } else {
      this.paymentError = false;
      this.formValues.bookingDeposit.token = result.paymentMethod.id;
      this.saveBookingDeposit();
    }
  };

  processPaymentFailure = (err) => {
    this.paymentError = err;
    this.isSavingPayment = false;
  };

  handleBamboraCheckout = async () => {
    try {
      const result = await new BamboraCheckout(this.facilityId).checkout(
        this.formValues.bookingDeposit.amount,
        !this.isDepositOnly ? this.formValues.email : ''
      );
      if (!result.transactionId) {
        this.isSavingPayment = false;
        return;
      }
      this.formValues.bookingDeposit.token = result.transactionId;
      this.formValues.bookingDeposit.bambora_session_token =
        result.sessionToken;
      this.paymentError = false;
      this.saveBookingDeposit();
    } catch (e) {
      this.processPaymentFailure(
        this.parseError(
          e,
          i18n.gettext(
            "There's been a problem. Please try again or choose another payment option."
          )
        )
      );
    }
  };

  // FORM ACTIONS
  handleSubmit = () => {
    if (this.step === 'CUSTOMFIELDS') {
      this.stepForward = true;
      this.step = 'CONTACT';
    } else if (this.step === 'CONTACT' && this.bookingSecurityCodeEnabled) {
      this.stepForward = true;
      this.step = 'VERIFY_SECURITY_CODE';
    } else if (this.step === 'VERIFY_SECURITY_CODE') {
      this.checkSecurityCode();
    } else if (this.step === 'DEPOSIT') {
      this.processPayment();
    } else if (this.isBookingDepositChargable()) {
      this.startDeposit();
    } else if (this.isAddingWaitlist) {
      this.saveWaitlist();
    } else {
      this.saveBooking();
    }
  };

  setPhone = (number, isValid) => {
    this.formValues.phone = number;
    this.phoneIsValid = isValid;
  };

  roomPickerOptions = () => {
    return [
      ...(this.anyRoomChoiceDisabled
        ? []
        : [
            {
              id: '',
              title: i18n.gettext('Any room'),
            },
          ]),
      ...this.facilityResourceGroups.map((room) => ({
        id: room.id,
        title: room.name,
      })),
    ];
  };

  // SECURITY CODE
  requestSecurityCode = async () => {
    try {
      this.securityCodeError = null;
      if (
        !this.oneTimeFacilityToken ||
        !this.oneTimeFacilityTokenExpiryDate ||
        moment().isAfter(this.oneTimeFacilityTokenExpiryDate)
      ) {
        const { data } = await axios.get(`/v1/facility/${this.facilityId}`);
        this.oneTimeFacilityToken = data.one_time_facility_token;
        this.oneTimeFacilityTokenExpiryDate = moment().add(
          this.ONE_TIME_FACILITY_TOKEN_TIMEOUT,
          'seconds'
        );
      }
      await axios.post(`/v1/bsc/${this.oneTimeFacilityToken}`, {
        facility_id: this.facilityId,
        email_address: this.formValues.email,
      });
      this.securityCodeExpiryDateByEmail.set(
        this.formValues.email,
        moment().add(this.SECURITY_CODE_TIMEOUT, 'seconds')
      );
    } catch (error) {
      this.securityCodeError =
        error.response?.data?.msg ||
        i18n.gettext('Error requesting security code');
    } finally {
      // one time facility token gets invalidated on every request
      this.oneTimeFacilityToken = null;
      this.oneTimeFacilityTokenExpiryDate = null;
    }
  };

  checkSecurityCode = async () => {
    try {
      this.securityCodeError = null;
      await axios.post(`/v1/bsc/check`, {
        facility_id: this.facilityId,
        email_address: this.formValues.email,
        security_code: this.formValues.security_code,
      });
      if (this.isBookingDepositChargable()) {
        this.startDeposit();
      } else if (this.isAddingWaitlist) {
        this.saveWaitlist();
      } else {
        this.saveBooking();
      }
    } catch (error) {
      this.securityCodeError =
        error.response?.data?.msg ||
        i18n.gettext('Error validating security code');
    }
  };
}

const appState = new AppState();
export default appState;
