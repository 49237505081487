<template>
  <div class="widget-content">
    <transition
      v-bind:name="
        state.stepForward ? 'translate-forward' : 'translate-backward'
      "
    >
      <w-booking
        v-if="state.step === 'BOOKING'"
        v-bind:state="state"
      ></w-booking>
      <w-custom-fields
        v-if="state.step === 'CUSTOMFIELDS'"
        v-bind:state="state"
      ></w-custom-fields>
      <w-contact
        v-else-if="state.step === 'CONTACT'"
        v-bind:state="state"
      ></w-contact>
      <w-deposit
        v-else-if="state.step === 'DEPOSIT'"
        v-bind:state="state"
      ></w-deposit>
      <w-deposit-confirm
        v-else-if="state.step === 'DEPOSIT_CONFIRM'"
        v-bind:state="state"
      ></w-deposit-confirm>
      <w-confirmation
        v-else-if="state.step === 'CONFIRMATION'"
        v-bind:state="state"
      ></w-confirmation>
      <w-cancel
        v-else-if="state.step === 'EXTERNAL'"
        v-bind:state="state"
      ></w-cancel>
      <w-cancelled-waitlist
        v-else-if="state.step === 'CANCEL_WAITLIST'"
        v-bind:state="state"
      ></w-cancelled-waitlist>
      <w-cancelled-not-waitlist
        v-else-if="state.step === 'CANCEL_NO_WAITLIST'"
        v-bind:state="state"
      ></w-cancelled-not-waitlist>
      <w-cancel-owner
        v-else-if="state.step === 'EXTERNAL_OWNER'"
        v-bind:state="state"
      ></w-cancel-owner>
      <w-cancelled-owner
        v-else-if="state.step === 'CANCELLED_OWNER'"
        v-bind:state="state"
      ></w-cancelled-owner>
      <w-confirmed-owner
        v-else-if="state.step === 'CONFIRMED_OWNER'"
        v-bind:state="state"
      ></w-confirmed-owner>
      <w-verify-security-code
        v-else-if="state.step === 'VERIFY_SECURITY_CODE'"
        v-bind:state="state"
      ></w-verify-security-code>
    </transition>
  </div>
</template>

<script>
import Booking from './Booking';
import Contact from './Contact';
import Deposit from './Deposit';
import DepositConfirm from './DepositConfirm';
import Confirmation from './Confirmation';
import Cancel from './Cancel';
import CancelledWaitlist from './CancelledWaitlist';
import CancelledNotWaitlist from './CancelledNotWaitlist';
import CancelOwner from './CancelOwner';
import CancelledOwner from './CancelledOwner';
import ConfirmedOwner from './ConfirmedOwner';
import CustomFields from './CustomFields.vue';
import VerifySecurityCode from './VerifySecurityCode.vue';

export default {
  components: {
    'w-booking': Booking,
    'w-contact': Contact,
    'w-deposit': Deposit,
    'w-deposit-confirm': DepositConfirm,
    'w-confirmation': Confirmation,
    'w-cancel': Cancel,
    'w-cancelled-waitlist': CancelledWaitlist,
    'w-cancelled-not-waitlist': CancelledNotWaitlist,
    'w-cancel-owner': CancelOwner,
    'w-cancelled-owner': CancelledOwner,
    'w-confirmed-owner': ConfirmedOwner,
    'w-custom-fields': CustomFields,
    'w-verify-security-code': VerifySecurityCode,
  },
  props: ['state'],
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-content {
  flex: 1 1 auto;
  display: flex;
  min-height: 0px; // <-- this lets flexbox calculate the height so we dont have to absolutely position anything
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.translate-forward-enter {
  transform: translateX(100%);
}

.translate-backward-enter {
  transform: translateX(-100%);
}

.translate-forward-enter-active,
.translate-backward-enter-active {
  transition: all 0.2s ease;
}
</style>
