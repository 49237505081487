<template>
  <div class="widget-deposit-content">
    <div class="widget-deposit-container">
      <div
        v-on:click="handleBackClick"
        :class="['widget-deposit-header', { disabled: isInfoHeaderDisabled }]"
      >
        <w-info-header :state="state"></w-info-header>
      </div>
      <w-expandable-text
        style="padding-top: 1rem"
        :text="txtDepositsWidgetDescription"
      ></w-expandable-text>
      <div class="widget-deposit-amount-container">
        <div class="widget-deposit-amount">
          <span>{{ txtDepositAmount }}:</span>
          <span>{{ formattedDepositAmount }}</span>
        </div>
        <div
          class="widget-deposit-cancellation-days"
          v-if="txtCancellationPolicy"
        >
          {{ txtCancellationPolicy }}
        </div>
      </div>
      <div class="widget-deposit-card-container">
        <w-AE></w-AE>
        <w-visa></w-visa>
        <w-MC></w-MC>
        <w-disc></w-disc>
        <w-cb></w-cb>
        <w-union></w-union>
      </div>
      <w-deposit-stripe
        v-if="showStripe"
        v-bind:state="state"
      ></w-deposit-stripe>
      <w-deposit-bambora
        v-if="showBambora"
        v-bind:state="state"
      ></w-deposit-bambora>
      <w-deposit-paypal
        v-if="showPayPal"
        v-bind:state="state"
      ></w-deposit-paypal>
      <div
        ref="errorContainer"
        v-if="errorMessage"
        class="widget-deposit-errors"
      >
        <b-message type="is-danger">
          {{ errorMessage }}
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from '../i18n';
import DepositStripe from './DepositStripe';
import DepositPayPal from './DepositPayPal';
import DepositBambora from './DepositBambora';
import ExpandableText from './common/ExpandableText';
import InfoHeader from './common/InfoHeader.vue';
import AmericanExpress from './icons/AmericanExpress.vue';
import Visa from './icons/Visa.vue';
import Mastercard from './icons/Mastercard.vue';
import Discover from './icons/Discover.vue';
import CB from './icons/CB.vue';
import Union from './icons/Union.vue';

export default {
  components: {
    'w-deposit-stripe': DepositStripe,
    'w-deposit-paypal': DepositPayPal,
    'w-deposit-bambora': DepositBambora,
    'w-expandable-text': ExpandableText,
    'w-info-header': InfoHeader,
    'w-AE': AmericanExpress,
    'w-visa': Visa,
    'w-MC': Mastercard,
    'w-disc': Discover,
    'w-cb': CB,
    'w-union': Union,
  },
  data: () => {
    return {
      txtDepositAmount: i18n.gettext('Deposit amount'),
    };
  },
  props: ['state'],
  watch: {
    errorMessage(newValue) {
      if (newValue) {
        this.focusErrorContainer();
      }
    },
  },
  computed: {
    isInfoHeaderDisabled: function () {
      return this.state.isDepositOnly;
    },
    txtDepositsWidgetDescription: function () {
      if (this.state.facilityBookingDepositDescription) {
        return this.state.facilityBookingDepositDescription.replaceAll(
          '%s',
          this.state.formatPrice(this.state.formValues.bookingDeposit.amount)
        );
      }
      if (
        this.state.stripeDelayedCharge &&
        this.state.formValues.bookingDeposit.payment_type === 'CCARD'
      ) {
        return i18n.sprintf(
          i18n.gettext(
            'In order to book a table at our restaurant, you have to deposit %s. This amount will be charged if you miss the booking or cancel too late. Thank you for your understanding!'
          ),
          this.state.formatPrice(this.state.formValues.bookingDeposit.amount)
        );
      }
      return i18n.sprintf(
        i18n.gettext(
          'In order to book a table at our restaurant, you have to deposit %s. This amount will be settled with your bill at the day of your visit or be retained if you miss the booking or cancel too late. Thank you for your understanding!'
        ),
        this.state.formatPrice(this.state.formValues.bookingDeposit.amount)
      );
    },
    formattedDepositAmount: function () {
      return this.state.formatPrice(
        this.state.formValues.bookingDeposit.amount
      );
    },
    isCancelWithoutFees: function () {
      if (this.state.facilityCancelWithoutFees) {
        return moment(this.cancelWithoutFeesDate).isAfter(moment());
      }
      return false;
    },
    bookingDate: function () {
      return this.state.isDepositOnly
        ? this.state.booking.date
        : this.state.selectedAvailability.date;
    },
    cancelWithoutFeesDate: function () {
      return moment(this.bookingDate).subtract(
        this.state.facilityCancelWithoutFeesDays,
        'days'
      );
    },
    txtFormattedDate: function () {
      return moment(this.bookingDate).format('DD.MM.yyyy HH:mm');
    },
    txtFormattedSummary: function () {
      return `${i18n.sprintf(
        i18n.ngettext('1 Guest', '%1d Guests', this.state.formValues.num),
        this.state.formValues.num
      )}, ${this.txtFormattedDate}`;
    },
    selectedRoomPickerOption() {
      if (!this.state.formValues.resourceGroup) {
        return null;
      }
      return this.state.facilityResourceGroups.find(
        (option) => option.id === this.state.formValues.resourceGroup
      );
    },
    errorMessage: function () {
      return this.state.paymentError || this.state.bookingError;
    },
    showStripe: function () {
      return this.state.facilityBookingDepositStripeEnabled;
    },
    showPayPal: function () {
      return this.state.facilityBookingDepositPayPalEnabled;
    },
    showBambora: function () {
      return this.state.facilityBookingDepositBamboraEnabled;
    },
    txtCancellationPolicy: function () {
      if (!this.state.cancelWithoutFeesDays) return;

      const cancelBeforeDate = moment(this.state.formValues.date).subtract(
        this.state.cancelWithoutFeesDays,
        'days'
      );

      if (moment().isAfter(cancelBeforeDate)) return;

      return i18n.sprintf(
        i18n.gettext('Free cancellation before %s'),
        cancelBeforeDate.format('LL')
      );
    },
  },
  methods: {
    handleBackClick: function () {
      this.state.stepForward = false;
      this.state.step = 'BOOKING';
    },
    focusErrorContainer() {
      this.$nextTick(() => {
        const errorContainer = this.$refs.errorContainer;
        if (errorContainer) {
          errorContainer.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-deposit-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.widget-deposit-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 1.5rem 0;
  & svg {
    margin: 0 4px;
    width: 3rem;
    height: 2.5rem;
  }
}

.widget-deposit-header {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 5px 0 10px 0;
  color: var(--theme-accent-color);
  cursor: pointer;

  @at-root #{$broadway} & {
    border-bottom: none;
  }

  &.disabled {
    pointer-events: none;
  }
}

.widget-deposit-header-summary {
  display: flex;
  color: var(--theme-accent-color);
  margin-top: $marginSize;
}

.widget-deposit-description {
  margin-top: auto;
  padding-bottom: 15px;
  font-size: 0.9em;

  @at-root #{$broadway} & {
    color: var(--theme-font-color);
  }
}

.widget-deposit-amount-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem;
  margin: 1.5rem 0;
  border: 1px solid var(--theme-font-color-opacity-5);
  border-radius: 10px;
  color: var(--theme-accent-color);

  @at-root #{$glass} & {
    background: var(--theme-background-color-opacity-7);
    border: none;
  }
  @at-root #{$broadway} & {
    border: none;
    box-shadow: 0px 0px 10px 0px var(--theme-accent-color);
  }
}
.widget-deposit-amount {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: calc(var(--font-size) * 1.25);
}

.widget-deposit-container {
  padding-top: 20px;
  padding: 0.75rem 1.5rem;
  @at-root #{$broadway} & {
    border-left: none;
    border-right: none;
  }
}
</style>
