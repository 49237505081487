<template>
  <div
    v-if="state.step !== 'CONFIRMATION'"
    v-bind:class="{
      'widget-footer': true,
      'widget-footer-room-options': widgetFooterRoomOptions,
    }"
  >
    <b-message
      v-if="showDepositMessage && state.step === 'BOOKING'"
      :title="txtDepositRequired"
      size="is-small"
      aria-close-label="Close"
      @close="handleCloseDepositMessage"
      class="deposit-required-message"
    >
    </b-message>
    <div v-if="isFinalBookingStep" class="widget-footer-legal">
      <div v-html="txtLegalConfirm"></div>
    </div>
    <div v-if="state.step === 'BOOKING'" class="widget-footer-buttons">
      <div class="widget-footer-button-left">
        <w-logo v-if="showLogo"></w-logo>
      </div>
      <div class="widget-footer-button-right">
        <button
          v-on:click.prevent="handleBookingSelectionNextClick"
          v-bind:disabled="!state.selectedAvailability.resource_uri"
          class="button is-primary"
        >
          {{ txtButtonNext }}
        </button>
      </div>
    </div>
    <div
      v-else-if="state.step === 'CUSTOMFIELDS'"
      class="widget-footer-buttons"
    >
      <div class="widget-footer-button-left">
        <button
          class="button is-outlined"
          v-bind:disabled="isSaving"
          v-on:click.prevent="handleCustomFieldsBackClick"
        >
          <w-arrow-left></w-arrow-left>
          {{ txtButtonBack }}
        </button>
      </div>
      <div class="widget-footer-button-right">
        <button
          ref="customfieldsButton"
          type="submit"
          v-bind:disabled="isSaving"
          v-bind:class="{
            button: true,
            'is-primary': true,
            'is-loading': isSaving,
            'custom-fields-next-button': true,
          }"
        >
          {{ txtButtonNext }}
        </button>
      </div>
    </div>
    <div v-else-if="state.step === 'CONTACT'" class="widget-footer-buttons">
      <div class="widget-footer-button-left">
        <button
          class="button is-outlined"
          v-bind:disabled="isSaving"
          v-on:click.prevent="handleBackFromContactClick"
        >
          <w-arrow-left></w-arrow-left>
          {{ txtButtonBack }}
        </button>
      </div>
      <div class="widget-footer-button-right">
        <button
          type="submit"
          v-bind:disabled="isSaving"
          v-bind:class="{
            button: true,
            'contact-send-request-button': true,
            'is-primary': true,
            'is-loading': isSaving,
          }"
        >
          {{ txtContactSubmit }}
        </button>
      </div>
    </div>
    <div
      v-else-if="state.step === 'VERIFY_SECURITY_CODE'"
      class="widget-footer-buttons"
    >
      <div class="widget-footer-button-left">
        <button
          class="button is-outlined"
          v-bind:disabled="isSaving"
          v-on:click.prevent="handleBackFromSecurityVerificationClick"
        >
          <w-arrow-left></w-arrow-left>
          {{ txtButtonBack }}
        </button>
      </div>
      <div class="widget-footer-button-right">
        <button
          type="submit"
          v-bind:disabled="
            isSaving ||
            !(
              state.formValues.security_code?.trim().length ===
              state.SECURITY_CODE_LENGTH
            )
          "
          v-bind:class="{
            button: true,
            'contact-send-request-button': true,
            'is-primary': true,
            'is-loading': isSaving,
          }"
        >
          {{ txtButtonNext }}
        </button>
      </div>
    </div>
    <div v-else-if="state.step === 'WAITLIST'" class="widget-footer-buttons">
      <div class="widget-footer-button-left">
        <button
          class="button is-outlined"
          v-bind:disabled="isSaving"
          v-on:click.prevent="handleBackFromContactClick"
        >
          <w-arrow-left></w-arrow-left>
          {{ txtButtonBack }}
        </button>
      </div>
      <div class="widget-footer-button-right">
        <button
          type="submit"
          v-bind:disabled="isSaving"
          v-bind:class="{
            button: true,
            'is-primary': true,
            'is-loading': isSaving,
          }"
        >
          {{ txtWaitlist }}
        </button>
      </div>
    </div>
    <div v-else-if="state.step === 'DEPOSIT'" class="widget-footer-buttons">
      <div class="widget-footer-button-left">
        <button
          class="button is-outlined"
          v-bind:disabled="isSaving"
          v-on:click.prevent="handleBackFromDepositClick"
          v-if="!state.isDepositOnly"
        >
          <w-arrow-left></w-arrow-left>
          {{ txtButtonBack }}
        </button>
      </div>
      <div class="widget-footer-button-right">
        <button
          type="submit"
          v-bind:disabled="
            isSaving ||
            state.formValues.bookingDeposit.payment_type === 'PAYPAL'
          "
          v-bind:class="{
            button: true,
            'is-primary': true,
            'is-loading': isSaving,
          }"
        >
          {{ txtContactSubmit }}
        </button>
      </div>
    </div>
    <div
      v-else-if="
        state.step === 'DEPOSIT_CONFIRM' &&
        !state.isChargingStripeSetupPaymentIntent
      "
      class="widget-footer-button-center"
    >
      <a
        v-if="state.depositStatus === 'setup_waiting'"
        :href="state.depositConfirmUrl"
        class="button is-primary"
      >
        {{ txtDepositConfirm }}
      </a>
    </div>
    <div v-else-if="state.step === 'CONFIRMATION'">
      <div class="widget-footer-button-center">
        <button
          class="button is-primary"
          v-on:click.prevent="handleMakeAnotherBookingClick"
          v-if="!state.isDepositOnly"
        >
          {{ txtMakeNewBooking }}
        </button>
      </div>
    </div>
    <div v-else class="widget-footer-button-center">
      <button
        v-if="state.step === 'EXTERNAL'"
        type="button"
        class="button widget-button-no-remove"
        v-on:click.prevent="state.cancelNotWaitlist"
      >
        {{ txtButtonNoRemoveRequest }}
      </button>
      <button
        v-if="state.step === 'EXTERNAL'"
        type="button"
        class="button widget-button-remove-waitlist"
        v-on:click.prevent="state.cancelWaitlistOrBooking"
      >
        {{ txtButtonRemoveRequest }}
      </button>
      <button
        v-if="
          ['EXTERNAL', 'CANCEL_WAITLIST', 'CANCEL_NO_WAITLIST'].includes(
            state.step
          ) && !state.isDepositOnly
        "
        type="button"
        class="button"
        v-on:click.prevent="handleMakeAnotherBookingClickFromWaitlist"
      >
        {{ txtMakeNewBooking }}
      </button>
      <button
        v-if="
          ['EXTERNAL_OWNER', 'CANCELLED_OWNER', 'CONFIRMED_OWNER'].includes(
            state.step
          )
        "
        type="button"
        class="button widget-button-view-app"
        v-on:click.prevent="viewInApp"
      >
        {{ txtButtonViewInApp }}
      </button>
      <button
        v-if="state.step === 'EXTERNAL_OWNER'"
        type="button"
        class="button widget-button-save"
        v-on:click.prevent="state.handleOwnerBookingAction"
        v-bind:disabled="!state.formValues.bookingResponse"
      >
        {{ txtButtonSave }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from '../i18n';
import Logo from './icons/Logo';
import ArrowLeft from './icons/ArrowLeft.vue';

export default {
  components: { 'w-logo': Logo, 'w-arrow-left': ArrowLeft },
  props: ['state'],
  data: () => {
    return {
      txtButtonNext: i18n.gettext('Next'),
      txtButtonBack: i18n.gettext('Back'),
      txtButtonConfirm: i18n.gettext('Confirm'),
      txtButtonSendRequest: i18n.gettext('Send request'),
      txtMakeNewBooking: i18n.gettext('Make a new booking'),
      txtWaitlist: i18n.gettext('Add to waitlist'),
      txtButtonSave: i18n.gettext('Save'),
      txtButtonViewInApp: i18n.gettext('View in app'),
      txtDepositConfirm: i18n.gettext('Confirm payment'),
      showDepositMessage: true,
    };
  },
  watch: {
    'state.formValues.date': function (newDate, oldDate) {
      if (
        !moment(newDate).isSame(oldDate) &&
        this.state.facilityBookingDepositEnabled
      ) {
        this.showDepositMessage = true;
      }
    },
  },
  computed: {
    isFinalBookingStep: function () {
      if (
        (this.txtContactSubmit !== this.txtButtonNext &&
          this.state.step === 'CONTACT') ||
        this.state.step === 'DEPOSIT'
      ) {
        return true;
      }
      return false;
    },
    isBooking: function () {
      if (!this.state.booking) {
        return false;
      }
      return this.state.booking.ref_num !== null;
    },
    isSaving: function () {
      return (
        this.state.isSavingBookingDeposit ||
        this.state.isSavingBooking ||
        this.state.isSavingPayment
      );
    },
    showLogo: function () {
      return !this.state.facilityHidePublicBranding;
    },
    widgetFooterRoomOptions: function () {
      return (
        !this.state.resourceGroup &&
        this.state.roomChoiceEnabled &&
        this.state.facilityResourceGroups.length > 1 &&
        this.state.step === 'BOOKING'
      );
    },
    txtContactSubmit: function () {
      if (this.state.isAddingWaitlist) {
        return this.txtWaitlist;
      }
      if (
        (!this.state.isBookingDepositDisabled(
          this.state.selectedAvailability.date
        ) &&
          this.state.step !== 'DEPOSIT') ||
        this.state.bookingSecurityCodeEnabled
      ) {
        return this.txtButtonNext;
      }
      if (
        this.state.facilityAutomaticallyConfirmBookings ||
        (this.state.facilityAutomaticallyConfirmBookingsUntilGroupSize &&
          this.state.formValues.num <=
            this.state.facilityBookingAutoConfirmationMaxGroupSize)
      ) {
        return this.txtButtonConfirm;
      }
      return this.txtButtonSendRequest;
    },
    txtLegalConfirm: function () {
      if (this.state.cancellationPolicyUrl) {
        return i18n.sprintf(
          i18n.gettext(
            'By clicking the %s button you agree to the <a target="_blank" href="%s" rel="nofollow">Terms & Conditions</a>, <a target="_blank" href="%s" rel="nofollow">Privacy Policy</a> and <a target="_blank" href="%s" rel="nofollow">Cancellation Policy</a>'
          ),
          this.txtContactSubmit,
          this.state.termsAndConditionsUrl,
          this.state.privacyPolicyUrl,
          this.state.cancellationPolicyUrl
        );
      }
      return i18n.sprintf(
        i18n.gettext(
          'By clicking the "%s" button you agree to the <a target="_blank" href="%s" rel="nofollow">Terms & Conditions</a> and <a target="_blank" href="%s" rel="nofollow">Privacy Policy</a>'
        ),
        this.txtContactSubmit,
        this.state.termsAndConditionsUrl,
        this.state.privacyPolicyUrl
      );
    },
    txtButtonRemoveRequest: function () {
      if (this.isBooking) {
        return i18n.gettext('Yes, cancel.');
      } else {
        return i18n.gettext('Yes, remove my request');
      }
    },
    txtButtonNoRemoveRequest: function () {
      if (this.isBooking) {
        return i18n.gettext("No, don't cancel.");
      } else {
        return i18n.gettext("No, don't remove it");
      }
    },
    txtDepositRequired: function () {
      return this.getDepositMessage();
    },
  },
  methods: {
    handleCloseDepositMessage() {
      this.showDepositMessage = false;
    },
    getDepositMessage() {
      // Here we set the message signaling whether a deposit is required for the currently selected day
      // The required times are a combination of the depositAllowedHours (horus for individual weekdays) and depositDateRanges (hours for day within date range)
      // To define the overall timespan, the depositAllowedHours and depositDateRanges are simply added together
      if (
        this.state.facilityBookingDepositGroupSize > this.state.formValues.num
      ) {
        return null;
      }
      const {
        formValues,
        facilityBookingDepositAllowedHours,
        facilityBookingDepositDateRanges,
      } = this.state;

      if (
        !facilityBookingDepositAllowedHours &&
        !facilityBookingDepositDateRanges
      ) {
        return;
      }

      const date = moment(formValues.date);
      const weekday = (date.day() + 6) % 7;
      const depositHours = facilityBookingDepositAllowedHours || [];
      const depositDateRanges = facilityBookingDepositDateRanges || [];

      // Find all matching date ranges for the current date
      const matchingDateRanges = depositDateRanges.filter((range) =>
        date.isBetween(range.start_date, range.end_date, null, '[]')
      );

      // Create a single array containing all the existing times (as moment objects) of the matching date ranges and deposit hours (for our current weekday)
      const times = [
        ...this.getCombinedTimes(matchingDateRanges),
        ...this.getCombinedTimes(depositHours[weekday]),
      ];

      if (times.length === 0) {
        return;
      }

      // Merge contiguous (touching / overlapping) time ranges together
      const mergedTimes = this.getMergedTimes(times);

      // Check if the period covers the whole day (00:00 - 23:55)
      const startAtMidnight = mergedTimes.some((range) =>
        range.start.isSame(moment('00:00', 'HH:mm'))
      );
      const endAtMidnight = mergedTimes.some((range) =>
        range.end.isSame(moment('23:55', 'HH:mm'))
      );
      const isWholeDay = startAtMidnight && endAtMidnight;

      let message;

      if (isWholeDay) {
        message = i18n.gettext('Deposit required for the whole day.');
      } else {
        // Combine time ranges into a single message
        const timeMessages = mergedTimes.map((range) =>
          i18n.sprintf(
            i18n.gettext('from %s to %s'),
            range.start.format('HH:mm'),
            range.end.format('HH:mm')
          )
        );
        message = i18n.sprintf(
          i18n.gettext('Deposit required %s.'),
          timeMessages.join(', ')
        );
      }

      return message;
    },
    getCombinedTimes: function (times) {
      return (times || []).map((item) => ({
        start: moment(item.begins, 'HH:mm'),
        end: moment(item.ends, 'HH:mm'),
      }));
    },
    getMergedTimes: function (times) {
      const sortedTimes = [...times].sort((a, b) => a.start - b.start);

      return sortedTimes.reduce((merged, current) => {
        if (merged.length === 0) {
          return [current];
        }

        const last = merged[merged.length - 1];

        if (last.end.isSameOrAfter(current.start)) {
          merged.splice(-1, 1, {
            ...last,
            end: moment.max(last.end, current.end),
          });
        } else {
          merged.push(current);
        }

        return merged;
      }, []);
    },
    handleCustomFieldsBackClick: function () {
      this.state.stepForward = false;
      this.state.step = 'BOOKING';
    },
    handleBookingSelectionNextClick: function () {
      this.state.stepForward = true;
      if (this.state.displayCustomFieldsStep()) {
        this.state.step = 'CUSTOMFIELDS';
      } else {
        this.state.step = 'CONTACT';
      }
    },
    handleBackFromContactClick: function (e) {
      if (this.state.displayCustomFieldsStep()) {
        this.state.stepForward = false;
        this.state.step = 'CUSTOMFIELDS';
      } else {
        this.state.getAvailabilities();
        this.state.stepForward = true;
        this.state.step = 'BOOKING';
      }
    },
    handleBackFromDepositClick: function (e) {
      this.state.formValues.bookingDeposit.payment_type = '';
      this.state.stepForward = false;
      this.state.step = 'CONTACT';
    },
    handleBackFromSecurityVerificationClick: function () {
      this.state.stepForward = false;
      this.state.step = 'CONTACT';
    },
    handleMakeAnotherBookingClick: function (e) {
      this.state.getAvailabilities();
      this.state.stepForward = true;
      this.state.step = 'BOOKING';
    },
    handleMakeAnotherBookingClickFromWaitlist: function (e) {
      window.location.href = `/${this.state.facilityId}/widget`;
    },
    viewInApp: function (e) {
      window.open(
        `/${this.state.facilityId}/bookings/edit/${this.state.booking.pk}`
      );
    },
  },
  mounted() {
    if (this.state.step === 'BOOKING') {
      this.showDepositMessage = this.state.facilityBookingDepositEnabled;
    }
    if (this.state.step === 'CUSTOMFIELDS') {
      this.$refs.customfieldsButton.focus();
    }
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-footer {
  position: relative;
  padding: calc($marginSize * 1.5) 0;

  border-top: 0.4px solid var(--theme-font-color-opacity-5);

  @at-root #{$glass} & {
    border: none;
  }
  @at-root #{$broadway} & {
    border: none;
  }
}

.widget-logo {
  height: 27px;
  width: 85px;

  @media (min-width: $padding-medium) {
    height: 30px;
    width: 90px;
  }
}

.widget-fb-text {
  display: none;
  @media (min-width: 275px) {
    display: block;
  }
}

.widget-footer-buttons {
  display: flex;
  align-items: center;
  padding: 0 calc($marginSize * 2);
  & button {
    @media (max-width: 300px) {
      font-size: 0.85em !important;
    }
  }
}

.widget-footer-button-left {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  & button {
    max-width: 44vw;
    padding: 5px 0.5rem;
  }

  @at-root #{$glass} & {
    .button {
      background: transparent;
    }
  }
}

.widget-footer-button-right {
  flex: 0 0 auto;
  & button {
    max-width: 50vw;
  }
}

.widget-footer-button-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding-top: 10px;
  text-align: center;

  & button {
    width: 100%;
  }
}

.widget-back {
  position: relative;
  width: 26px;
  height: 26px;

  &::after {
    border: 2px solid currentColor;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 10px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 6px;
    transform: rotate(45deg);
    transform-origin: center;
    width: 10px;
  }
}

.button.widget-button-no-remove,
.button.widget-button-view-app {
  background-color: none;

  &,
  &:hover,
  &:focus {
    border: 1px solid var(--theme-accent-color);
    color: var(--theme-accent-color);
  }
}

.button.widget-button-remove-waitlist {
  background-color: $danger;

  &,
  &:hover,
  &:focus {
    border: 0px;
    color: white;
  }
}

.button.widget-button-save {
  background-color: $green !important;

  &,
  &:hover,
  &:focus {
    border: 0px;
    color: white;
  }
}

.widget-footer-legal {
  color: var(--theme-font-color-opacity-6);
  line-height: 15px;
  font-size: 0.8em;
  padding: 0 calc($marginSize * 2) 0.5rem;

  @at-root #{$broadway} & {
    padding-top: 0.5rem;
    border-top: 1px solid var(--theme-accent-color);
    box-shadow: 0px -5px 5px -5px var(--theme-accent-color-opacity-5);
  }

  a {
    color: var(--theme-accent-color-opacity-6);
  }

  a:hover {
    color: var(--theme-accent-color);
  }
}

.deposit-required-message {
  position: absolute;
  bottom: 100%;
  width: 100%;
  margin-bottom: 0.5rem !important;
  padding: 0 1rem;
  background: transparent !important;
  font-size: var(--font-size) !important;

  .message-header {
    position: relative;
    border-radius: 10px !important;
    background: var(--theme-background-color);
    color: var(--theme-font-color);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    font-weight: initial;
    padding: 0.5rem 0.75rem;

    @at-root #{$broadway} & {
      border: 1px solid var(--theme-accent-color) !important;
      border-radius: 20px !important;
      padding: 0.5rem 0.75rem;
    }

    p {
      z-index: 2;
    }

    .delete {
      background-color: transparent;
      padding: 1rem;
      z-index: 2;

      &:hover {
        &:before {
          width: 70%;
          height: 2px;
        }
        &:after {
          height: 70%;
          width: 2px;
        }
      }

      &:before,
      &::after {
        background-color: var(--theme-font-color);
      }

      &::before {
        width: 60%;
        height: 1.75px;
      }
      &::after {
        height: 60%;
        width: 1.75px;
      }
    }
  }

  .message-body {
    display: none;
  }
}
</style>
