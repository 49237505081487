import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import Buefy from 'buefy/dist/buefy';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';

import App from './App';

import configureSentry from './configureSentry';
import configureMoment from './configureMoment';
import configureStyles from './configureStyles';

import appConfig from './appConfig';
import appState from './appState';

import './styles/index.scss';

configureMoment(appConfig);
configureSentry(appConfig);
configureStyles(appConfig);

appState.init(appConfig);

Vue.use(Buefy, {
  defaultDayNames: moment.weekdaysMin(),
  defaultMonthNames: moment.months(),
  defaultFirstDayOfWeek: moment.localeData().firstDayOfWeek(),
});

document.addEventListener(
  'DOMContentLoaded',
  () => {
    new Vue({ render: (h) => h(App) }).$mount('#widget');
  },
  false
);

// used for CDN fallback detection
window.resmioLoaded = true;
