<template>
  <div class="widget-confirmation-content">
    <div
      v-bind:class="{
        'widget-confirmation-message': true,
        'widget-confirmation-unconfirmed': !isConfirmed,
      }"
    >
      <div v-if="state.isAddingWaitlist" class="widget-confirmation-header">
        <w-waitlist></w-waitlist>
      </div>
      <div v-else class="widget-confirmation-header">
        <w-success></w-success>
      </div>
      <div v-if="isConfirmed" class="widget-confirmation-confirmed">
        <p class="widget-confirmation-header-description">{{ txtThankYou }}</p>
        <p class="widget-confirmation-header-description">
          {{ txtDescription }}
        </p>
      </div>
      <div v-else class="widget-confirmation-confirmed">
        <div class="widget-confirmation-header">{{ txtThankYou }}</div>
        <p v-if="state.isAddingWaitlist">{{ txtWaitListReceived }}</p>
        <p v-else>{{ txtReceived }}</p>
      </div>
      <div class="widget-confirmation-booking-info">
        <div class="widget-confirmation-heading" v-if="txtFormattedPromotion">
          {{ txtFormattedPromotion }}
        </div>
        <div
          v-else-if="!state.isAddingWaitlist"
          class="widget-confirmation-heading"
        >
          {{ txtHeading }}
        </div>
        <div
          v-bind:class="[
            'widget-confirmation-grid',
            { 'widget-confirmation-grid-waitlist': state.isAddingWaitlist },
          ]"
        >
          <div class="widget-confirmation-row">
            <span class="widget-confirmation-row-heading">{{ txtDate }}</span>
            <div class="widget-confirmation-row-content">
              {{ txtFormattedDate }}
            </div>
          </div>
          <div class="widget-confirmation-row widget-confirmation-row-time">
            <span class="widget-confirmation-row-heading">{{ txtTime }}</span>
            <div class="widget-confirmation-row-content">
              {{ txtFormattedTime }}
            </div>
          </div>
          <div
            class="widget-confirmation-row widget-confirmation-row-guest-num"
          >
            <span class="widget-confirmation-row-heading">{{
              txtGuestNum
            }}</span>
            <div class="widget-confirmation-row-content">
              {{ state.formValues.num }}
            </div>
          </div>
          <div
            class="widget-confirmation-row"
            v-if="txtSelectedArea && !state.isAddingWaitlist"
          >
            <span class="widget-confirmation-row-heading">{{ txtArea }}</span>
            <div class="widget-confirmation-row-content">
              {{ txtSelectedArea }}
            </div>
          </div>
          <div v-else></div>
          <div
            class="widget-confirmation-row"
            v-if="state.booking.booking_deposit"
          >
            <span class="widget-confirmation-row-heading">{{ txtAmount }}</span>
            <div class="widget-confirmation-row-content">
              {{ txtFormattedAmount }}
            </div>
          </div>
          <div
            class="widget-confirmation-row"
            v-if="state.booking.booking_deposit"
          >
            <span class="widget-confirmation-row-heading">{{
              txtPaymentMethod
            }}</span>
            <div class="widget-confirmation-row-content">
              {{ txtFormattedPaymentType }}
            </div>
          </div>
        </div>
        <div class="widget-confirmation-row" v-if="state.isAddingWaitlist">
          <span class="widget-confirmation-row-heading">{{
            txtguestInfo
          }}</span>
          <div class="widget-confirmation-waitlist-guest-info">
            <span>
              {{ state.booking.name }}
            </span>
            <span>
              {{ state.booking.email }}
            </span>
            <span>
              {{ state.booking.phone }}
            </span>
          </div>
        </div>
        <div
          v-else
          class="widget-booking-receipt"
          v-html="txtBookingCode"
        ></div>
      </div>
    </div>

    <div class="widget-footer-button-center">
      <button
        class="button is-primary"
        v-on:click.prevent="handleMakeAnotherBookingClick"
        v-if="!state.isDepositOnly"
      >
        {{ txtMakeAnotherBooking }}
      </button>
    </div>
    <div v-if="state.isAddingWaitlist" class="widget-booking-receipt-info">
      {{ txtWaitlistTableInfo }}
    </div>
    <div v-else>
      <div v-if="isConfirmed" class="widget-booking-receipt-info">
        {{ txtEmail }}
      </div>
      <div
        v-if="txtCancelWithoutFeesDays"
        class="widget-booking-receipt-cancellation-days"
      >
        {{ txtCancelWithoutFeesDays }}
      </div>
      <div
        v-else-if="!isConfirmed"
        class="widget-confirmation-row-heading"
        style="padding: 1rem 0 1.25rem 0"
      >
        <p>
          <strong class="widget-confirmation-unconfirmed">{{
            txtNotConfirmed
          }}</strong>
        </p>
        <p class="widget-confirmation-unconfirmed">{{ txtNotConfirmedMore }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import i18n from '../i18n';
import Success from './icons/Success';
import Waitlist from './icons/Waitlist';

export default {
  components: { 'w-success': Success, 'w-waitlist': Waitlist },
  props: ['state'],
  data: () => {
    return {
      txtDate: i18n.gettext('Date'),
      txtTime: i18n.gettext('Time'),
      txtGuestNum: i18n.gettext('Guest No.'),
      txtArea: i18n.gettext('Area'),
      txtAmount: i18n.gettext('Amount'),
      txtPaymentMethod: i18n.gettext('Payment Method'),
      txtguestInfo: i18n.gettext('Guest Info'),
      txtThankYou: i18n.gettext('Thank you!'),
      txtMakeAnotherBooking: i18n.gettext('Make another booking'),
      txtReceived: i18n.gettext('We have received your booking request.'),
      txtWaitListReceived: i18n.gettext('Your booking is now on our waitlist.'),
      txtNotConfirmed: i18n.gettext('Your booking has not been confirmed yet!'),
      txtNotConfirmedMore: i18n.gettext(
        'We will contact you as soon as your booking is confirmed.'
      ),
      txtEmail: i18n.gettext(
        "We've sent your confirmation to your email address."
      ),
      txtWaitlistTableInfo: i18n.gettext(
        'We will notify you in case a table becomes available.'
      ),
    };
  },
  computed: {
    isConfirmed: function () {
      return (
        this.state.booking.status === 'confirmed' ||
        this.state.isDepositCharged ||
        this.state.depositStatus === 'setup_success'
      );
    },
    paymentTypes: function () {
      return {
        CCARD: i18n.gettext('Credit card'),
        'SEPA-DD': i18n.gettext('Sepa'),
        PAYPAL: i18n.gettext('PayPal'),
        BAMBORA: i18n.gettext('Credit card (Bambora)'),
        OUTSTANDING: i18n.gettext('Unpaid'),
        VOUCHER: i18n.gettext('Voucher'),
      };
    },
    txtDescription: function () {
      return this.state.isDepositCharged
        ? i18n.gettext('You already paid the deposit.')
        : i18n.gettext('Your booking has been confirmed.');
    },
    txtFormattedDate: function () {
      return moment(this.state.booking.date).format('DD.MM.YYYY');
    },
    txtFormattedTime: function () {
      const { date, booking_timespan } = this.state.booking;
      const startTime = moment(date).format('HH:mm');
      const endTime = moment(date)
        .add(booking_timespan, 'minutes')
        .format('HH:mm');
      return `${startTime}${
        this.state.facilityReservationEndTimeEnabled ? ` - ${endTime}` : ''
      }`;
    },
    txtBookingCode: function () {
      return i18n.sprintf(
        i18n.gettext('Booking number: <span>%s</span>'),
        this.state.booking.ref_num
      );
    },
    txtSelectedArea: function () {
      const { resourceGroupName, formValues, facilityResourceGroups } =
        this.state;

      if (resourceGroupName) {
        return resourceGroupName;
      }
      if (formValues.resourceGroup) {
        return facilityResourceGroups.find(
          (option) => option.id === formValues.resourceGroup
        ).name;
      }
      return null;
    },
    txtFormattedPromotion: function () {
      return this.state.promoTimesById[this.state.booking.promo]?.promo;
    },
    txtHeading: function () {
      if (this.state.selectedAvailability.promo) {
        return this.state.promoTimesById[this.state.selectedAvailability.promo]
          ?.promo;
      }
      return i18n.gettext('Your reservation');
    },
    txtFormattedAmount: function () {
      return this.state.formatPrice(this.state.booking.booking_deposit?.amount);
    },
    txtFormattedPaymentType: function () {
      return (
        this.paymentTypes[this.state.booking.booking_deposit?.payment_type] ||
        ''
      );
    },
    txtCancelWithoutFeesDays: function () {
      if (!this.state.cancelWithoutFeesDays) return;
      const cancelBeforeDate = moment(this.state.booking.date).subtract(
        this.state.cancelWithoutFeesDays,
        'days'
      );

      if (moment().isAfter(cancelBeforeDate)) return;

      return i18n.sprintf(
        i18n.gettext('Free cancellation before %s.'),
        cancelBeforeDate.format('LL')
      );
    },
  },
  methods: {
    handleMakeAnotherBookingClick: function (e) {
      this.state.getAvailabilities();
      this.state.stepForward = true;
      this.state.securityCodeExpiryDateByEmail.delete(
        this.state.formValues.email
      );
      this.state.step = 'BOOKING';
    },
  },
  mounted() {
    window.gtag?.('event', 'screen_view', {
      app_name: 'widget',
      screen_name: 'confirmation',
    });
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-confirmation-heading {
  font-size: calc(var(--font-size) * 2);
  overflow-wrap: break-word;
  font-weight: 800;
  padding-bottom: 1.25rem;
  text-align: left;
  color: var(--theme-accent-color);
}

.widget-confirmation-grid {
  width: 100%;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(2, 1fr);
}

.widget-confirmation-grid-waitlist {
  grid-template-columns: repeat(3, 1fr);
  justify-items: start;

  & .widget-confirmation-row-guest-num {
    grid-row-start: 1;
  }

  & .widget-confirmation-row-time {
    padding-left: 1rem;
  }
}

.widget-confirmation-row {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--theme-accent-color);
}

.widget-confirmation-row-heading {
  font-size: calc(var(--font-size) * 0.9);
}

.widget-confirmation-row-content {
  font-size: calc(var(--font-size) * 1.25);
  font-weight: 700;
}

.widget-confirmation-content {
  overflow-y: auto;
  padding: 0 2rem;
  @media (min-width: $padding-medium) {
    padding: 0 3rem;
  }
}

.widget-confirmation-booking-info-promo-text {
  font-weight: 500;
}

.widget-confirmation-header {
  background: transparent;
  padding: 0 1rem;
  color: var(--theme-accent-color);
}

.widget-confirmation-header-description {
  font-weight: 600;
  padding: 0 1.25rem;
}

.widget-confirmation-message {
  border: none;
  text-align: center;
  padding-top: 2rem;
}

.widget-confirmation-booking-info {
  font-size: calc(var(--font-size) * 1.25);
  padding: 1rem;
  margin: 1.5rem 0 0.25rem 0;
  border-radius: 10px;
  border: 1px solid var(--theme-accent-color);
  color: var(--theme-accent-color);

  @at-root #{$broadway} & {
    box-shadow: 0px 0px 7px var(--theme-accent-color);
  }

  @at-root #{$glass} & {
    background: var(--theme-background-color-opacity-6);
    border: none;
  }
}

.widget-waitlist-contact-info {
  padding: 15px 5px;
}

.widget-confirmation-unconfirmed-text {
  margin-top: -16px;
}

.widget-confirmation-confirmed {
  color: var(--theme-accent-color);
}

.widget-booking-receipt {
  padding-top: 20px;
  text-align: left;
  color: var(--theme-accent-color);
  font-weight: 600;

  @at-root #{$broadway} &,
    #{$glass} & {
    color: var(--theme-accent-color);
  }
}

.widget-booking-receipt-info {
  color: var(--theme-accent-color);
  padding: 1rem 0 0.5rem 0;
}

.widget-booking-receipt-cancellation-days {
  color: var(--theme-accent-color);
  padding-bottom: 1.5rem;
}

.widget-booking-confirmation-unconfirmed {
  padding: 0px 15px;
}

.widget-confirmation-waitlist-guest-info {
  display: flex;
  flex-direction: column;
  color: var(--theme-accent-color);
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.widget-confirmation-unconfirmed {
  font-size: 0.75rem;
  color: var(--theme-accent-color);
}
</style>
