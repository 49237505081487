<template>
  <div class="widget-pin-section">
    <div class="widget-pin-heading">
      {{ txtHeader }}
    </div>
    <div class="widget-pin-subheading" v-html="txtSubHeader"></div>
    <w-pin-input
      @change="handleChanged"
      :error="!!error"
      :length="state.SECURITY_CODE_LENGTH"
      :initialValue="state.formValues.security_code"
    ></w-pin-input>
    <div v-if="!error && !timeout" class="widget-pin-info-text">
      <span>{{ txtEmailConfirmation }}</span>
      {{ ' ' }}
      <a href="#" @click.prevent="handleSendAgain" role="button">{{
        txtSendAgain
      }}</a>
    </div>
    <div v-else-if="timeout" class="widget-pin-info-text">
      <span>{{ txtTimeout }}</span>
      {{ ' ' }}
      <a href="#" @click.prevent="handleSendAgain" role="button">{{
        txtTryAgain
      }}</a>
    </div>
    <div v-else-if="error" class="widget-pin-info-text error">
      <span>{{ error }}</span>
      {{ ' ' }}
      <a href="#" @click.prevent="handleSendAgain" role="button">{{
        txtRequestNewCode
      }}</a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import i18n from '../i18n';
import PINInput from './PinInput.vue';

export default {
  components: {
    'w-pin-input': PINInput,
  },
  props: ['state'],
  data: function () {
    return {
      timeout: undefined,
      txtHeader: i18n.gettext('Enter PIN'),
      txtTryAgain: i18n.gettext('Try again.'),
      txtSendAgain: i18n.gettext('Send again.'),
      txtRequestNewCode: i18n.gettext('Request a new code.'),
      txtEmailConfirmation: i18n.gettext("You didn't get the email? "),
    };
  },
  computed: {
    error: function () {
      return this.state.securityCodeError;
    },
    formattedTimeout: function () {
      const seconds = Math.round(this.timeout / 1000);
      const minutes = Math.round(seconds / 60);
      return seconds < 60
        ? `${seconds} ${i18n.gettext('seconds')}`
        : `${minutes} ${i18n.gettext('minutes')}`;
    },
    txtSubHeader: function () {
      const email = this.state.formValues.email;
      return i18n.sprintf(
        i18n.gettext(
          'We have sent an email to %s with a PIN. Enter it below to continue.'
        ),
        `<span class="email">${email}</span>`
      );
    },
    txtTimeout: function () {
      return i18n.sprintf(
        i18n.gettext('You need to wait %s in order to resend the email.'),
        this.formattedTimeout
      );
    },
  },
  methods: {
    handleSendAgain: function () {
      if (this.canRequestSecurityCode()) {
        this.state.requestSecurityCode();
      } else {
        this.timeout = moment(this.getSecurityCodeExpiryDate()).diff(moment());
        this.timeoutId = setTimeout(this.resetTimeout.bind(this), this.timeout);
      }
    },
    handleChanged: function (value) {
      this.state.formValues.security_code = value;
    },
    resetTimeout: function () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeout = undefined;
    },
    // the following two methods dont work as computed properties as vue
    // does not track maps or adding / deleting keys to / from objects
    getSecurityCodeExpiryDate: function () {
      return this.state.securityCodeExpiryDateByEmail.get(
        this.state.formValues.email
      );
    },
    canRequestSecurityCode: function () {
      const securityCodeExpiryDate = this.getSecurityCodeExpiryDate();
      return (
        !securityCodeExpiryDate || moment().isAfter(securityCodeExpiryDate)
      );
    },
  },
  watch: {
    error: function () {
      this.resetTimeout();
    },
  },
  mounted: function () {
    if (this.canRequestSecurityCode()) {
      this.state.requestSecurityCode();
    }
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-pin-section {
  padding: 0 2rem;
}

.widget-pin-heading {
  color: var(--theme-accent-color);
  text-align: center;
  font-size: calc(var(--font-size) * 1.5);
  font-weight: 600;
  padding-bottom: 0.75rem;
}

.widget-pin-subheading {
  padding-bottom: 1rem;
  font-size: var(--font-size);

  .email {
    font-weight: 600;
  }
}

.widget-pin-info-text {
  font-size: calc(var(--font-size) * 0.75);
  padding-top: 1rem;

  &.error {
    color: $danger;
  }
}
</style>
